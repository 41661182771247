<template>
  <div>
    <h2 v-html="$t('search_card_message_title')"></h2>
    <v-text-field
      id="search_card_message_messageTitle"
      v-model="SearchMessageStore.messageTitle"
      :label="$t('search_card_message_messageTitle')"
      type="text"
      @keyup.enter="search"
    />
    <DbmDatePicker
      id="search_card_message_creationDateFrom"
      v-model="SearchMessageStore.creationDateFrom"
      :dateLabel="$t('search_card_message_creationDateFrom')"
      type="date"
      @keyup.enter="search"
      :clearable="true"
    />
    <DbmDatePicker
      id="search_card_message_creationDateTo"
      v-model="SearchMessageStore.creationDateTo"
      :dateLabel="$t('search_card_message_creationDateTo')"
      type="date"
      @keyup.enter="search"
      :clearable="true"
    />
    <v-select
      :items="messageTypes"
      item-value="name"
      :item-title="$route.params.langKey"
      v-model="SearchMessageStore.messageType"
      :clearable="true"
      :label="$t('search_card_message_type')"
      @keyup.enter="search"
    ></v-select>
    <v-select
      :items="sendingChannels"
      item-value="name"
      item-title="name"
      v-model="SearchMessageStore.sendingChannel"
      :clearable="true"
      :label="$t('search_card_message_channel')"
      @keyup.enter="search"
    ></v-select>
    <v-select
      :items="senderWithFullNames"
      item-value="id"
      item-title="fullName"
      v-model="SearchMessageStore.senderId"
      :clearable="true"
      :label="$t('search_card_message_sender_all')"
      @keyup.enter="search"
    ></v-select>
    <v-select
      id="search_card_message_status_read"
      :label="$t('search_card_message_status_read')"
      v-model="SearchMessageStore.read"
      :items="booleanOptionsRead"
      item-title="text"
      item-value="status"
      clearable
    />
    <v-select
      id="search_card_message_attachments"
      :label="$t('search_card_message_attachments')"
      v-model="SearchMessageStore.attachments"
      :items="booleanOptionsAttachments"
      item-title="text"
      item-value="status"
      clearable
    />
    <v-text-field id="search_card_message_tags" v-model="SearchMessageStore.tags" :label="$t('search_card_message_tags')" type="text" @keyup.enter="search" />
    <v-switch
      id="search_card_message_visualization_reduced"
      v-model="SearchMessageStore.reduced"
      color="primary"
      :label="$t('search_card_message_visualization_reduced')"
      @keyup.enter="search"
    ></v-switch>
  </div>
</template>

<script>
import baseSearchVue from './baseSearch.vue'
import DbmDatePicker from '@/components/dbmDatePicker.vue'
import { app, apiURL } from '@/main'
import { showError } from '@/services/axios'
import { useMessageTypesStore } from '@/store/enums/MessageTypesStore'

export default {
  name: 'searchMessage',
  components: { DbmDatePicker },
  extends: baseSearchVue,
  data() {
    return {
      loadingSender: false,
      booleanOptionsRead: [
        { id: 0, status: true, text: this.$t('search_card_message_status_read_true') },
        { id: 1, status: false, text: this.$t('search_card_message_status_read_false') }
      ],
      booleanOptionsAttachments: [
        { id: 0, status: true, text: this.$t('search_card_message_attachments_true') },
        { id: 1, status: false, text: this.$t('search_card_message_attachments_false') }
      ],
      sendingChannels: ['SMS', 'EMAIL', 'POST', 'INTERNAL']
    }
  },
  computed: {
    SearchMessageStore() {
      return this.$store.state.SearchMessageStore
    },
    messageTypes() {
      return useMessageTypesStore().messageTypes
    },
    senderWithFullNames() {
      if (this.$store.state.SearchMessageStore.senderItems == null) {
        return []
      }
      return this.$store.state.SearchMessageStore.senderItems.map((item) => {
        return {
          id: item.id,
          fullName: `${item.name1 !== null ? item.name1 : ''} ${item.name2 !== null ? item.name2 : ''}`.trim()
        }
      })
    }
  },
  methods: {
    search() {
      this.$emit('search')
    },
    async getSender() {
      try {
        this.loadingSender = true
        this.items = []
        const response = await app.axios.get(apiURL + '/messages/sender', { params: {} }, { headers: { 'Content-Type': 'application/json' } })
        this.$store.state.SearchMessageStore.senderItems = await response.data
      } catch (e) {
        showError(e)
      } finally {
        this.loadingSender = false
      }
    },
    reset() {
      this.resetFields(this.SearchMessageStore)
    }
  },
  beforeMount() {
    useMessageTypesStore().fetchMessageTypes
  },
  mounted() {
    this.initSearchStoreFromQuery(this.SearchMessageStore, this.$route.query)
    this.getSender()
  }
}
</script>
